import '../styles/profile.scss';
import '../styles/common.scss';
import '../styles/button.scss';
import '../styles/swal.scss';

import { gql,useMutation } from '@apollo/client';
import { useAuth0 } from '@auth0/auth0-react';
import { Typography } from '@mui/material';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import TextField from '@mui/material/TextField';
// import Grid from '@mui/material/Unstable_Grid2';
import Box  from '@mui/system/Box';
import { navigate } from 'gatsby';
import React, { useContext, useEffect, useRef,useState } from 'react';
import Swal from 'sweetalert2';

import Footer from '../components/footer';
import ButtonAppBar from '../components/header';
import ProfilePhoto from '../components/profile-photo';
import Upload from '../components/upload';
import UrlComponent from '../components/UrlValidations/UrlValidation';
// import UrlComponent2 from '../components/UrlValidations/UrlValidation2';
// import UrlComponent3 from '../components/UrlValidations/UrlValidation3';
// import UrlComponent4 from '../components/UrlValidations/UrlValidation4';
import UrlComponent5 from '../components/UrlValidations/UrlValidation5';
import UserContext from '../context/context';

const PARTNER_UPDATE = gql`
   mutation MyMutation($_eq: String!, $company: String = "", $custom_url: String = "", $description: String = "", $email: String = "", $facebook: String = "", $instagram: String = "", $full_name: String = "", $linkedin: String = "", $profile_url: String = "", $twitter: String = "") {
    update_agentportalagents(where: {auth0_id: {_eq: $_eq}}, _set: {company: $company, custom_url: $custom_url, description: $description, email: $email, facebook: $facebook, instagram: $instagram, full_name: $full_name, linkedin: $linkedin, profile_url: $profile_url, twitter: $twitter}) {
      returning {
        auth0_id
        company
        custom_url
        description
        email
        facebook
        full_name
        instagram
        linkedin
        phone_no
        profile_url
        twitter
      }
    }
  }
`;

const PROD_PARTNER_UPDATE = gql`
   mutation MyMutation($_eq: String!, $company: String = "", $custom_url: String = "", $description: String = "", $email: String = "", $facebook: String = "", $instagram: String = "", $full_name: String = "", $linkedin: String = "", $profile_url: String = "", $twitter: String = "") {
    update_agentportalagents: prod_update_agentportalagents(where: {auth0_id: {_eq: $_eq}}, _set: {company: $company, custom_url: $custom_url, description: $description, email: $email, facebook: $facebook, instagram: $instagram, full_name: $full_name, linkedin: $linkedin, profile_url: $profile_url, twitter: $twitter}) {
      returning {
        auth0_id
        company
        custom_url
        description
        email
        facebook
        full_name
        instagram
        linkedin
        phone_no
        profile_url
        twitter
      }
    }
  }
`;


export default function PartnerProfile() {

  const [updateAgent, { data, loading, error }] = useMutation( process.env.GATSBY_ENV == 'PROD' ? PROD_PARTNER_UPDATE : PARTNER_UPDATE );
  const { agent } = useContext( UserContext ) || { agent: {} };

  // const [email, setEmail] = useState( agent?.email  || '' );
  const [company, setCompany] = useState( agent?.company || '' );
  const [profilePic, setProfilePic] = useState( agent?.profile_url  );
  // const [name, setName] = useState( `${agent?.first_name  || ''} ${agent?.last_name || ''}` );

  const fileInput = useRef();
  const [name, setName] = useState( agent?.full_name?.trim() || ( `${agent?.first_name  || ''} ${agent?.last_name || ''}` ).trim() );
  const [email, setEmail] = useState( agent?.email  || ''  );
  const [description, setDescription] = useState( '' );
  const [nameError, setNameError] = useState( '' );
  const [pageLoad, setPageLoad] = useState( true );
  const [emailError, setEmailError] = useState( '' );
  const [companyError, setCompanyError] = useState( '' );
  const [descriptionError, setDescriptionError] = useState( '' );
  const [photo, setPhoto] = useState( '' );
  const [formSubmitted, isFormSubmitted] = useState( false );
  const [validation, runValidation] = useState( 0 );
  // const [description, setDescription] = useState( agent?.description || '' );
  const [social, setSocial] = useState( {
    instagram: agent?.instagram || '',
    facebook: agent?.facebook || '',
    linkedin: agent?.linkedin || '',
    twitter: agent?.twitter || ''
  } );
  const [customUrl, setCustomUrl] = useState( agent?.custom_url || '' );
  const [disableButton, setDisableButton] = useState( true );
  const [validators, setValidator] = useState( {} );

  const [isEnabled, setEnabledState] = useState( false );

  useEffect( () => {
    setEnabledState( name?.length > 0 &&
  email?.length > 0 &&
  company?.length > 0 ); },[name] );


  useEffect( () => {
    console.log( isEnabled );
  }, [isEnabled] );
  // social?.length > 0;
  // social.linkedin?.length > 0 &&
  // social.instagram?.length > 0 &&
  // social.twitter?.length > 0 &&
  // social.facebook?.length > 0;


  const { isLoading: authLoading, isAuthenticated } = useAuth0();
  useEffect( () => {
    if( !( authLoading || isAuthenticated ) ){
      navigate( '/login' );
    }
  }, [authLoading] );

  function firePopUp( text ){
    Swal.fire( {
      imageUrl: 'https://cdn.getmybubble.com/images/corporate-sites/images/education2.png',
      imageHeight: 151,
      imageWidth: 125,
      title: '',
      text,
      type: 'warning'
    } );
  }

  useEffect( () => {
    setSocial( {
      instagram: agent.instagram,
      facebook: agent.facebook,
      linkedin: agent.linkedin,
      twitter: agent.twitter
    } );
    setCustomUrl( agent.custom_url );
    setProfilePic( agent.profile_url || 'https://public-bubble.s3.us-west-2.amazonaws.com/images/bubb.svg' );
    setEmail(  agent.email );
    setCompany( agent.company );
    setName(  agent?.full_name?.trim() || `${agent?.first_name
      || ''} ${agent?.last_name || ''}` );
    setDescription( agent.description );
  }, [agent] );

  function updateSocial( key, value ){
    const socials = {
      instagram: social.instagram,
      twitter: social.twitter,
      facebook: social.facebook,
      linkedin: social.linkedin
    };
    socials[key] = value;
    setSocial( socials );
  }

  function validateForm(){
    const isValidForm = Object.keys( validators ).every( key => {
      console.log( validators[key], key );return validators[key];
    } );

    console.log( isValidForm, 'is form valid' );

    return isValidForm;
  }

  function updateProfilePicUrl( url ){
    setProfilePic( url );
  }

  async function sendForm( event ){
    document.getElementById( event.target.id ).style.pointerEvents = 'none';
    isFormSubmitted( true );
    runValidation( validation + 1 );

    setTimeout( () => {
      const isValid = validateForm();
      console.log( isValid, 'is form valid' );
      if ( isValid ){
        setDisableButton( true );
        firePopUp( 'Your profile is updated' );
        updateAgent( { variables:
        { _eq: agent.auth0_id,
          company,
          custom_url: customUrl,
          description,
          email,
          facebook: social.facebook,
          full_name: name,
          first_name: name.split( ' ' )[0],
          last_name:  name.split( ' ' )[1],
          instagram: social.instagram,
          linkedin: social.linkedin,
          profile_url: profilePic,
          twitter: social.twitter
        }
        } )
          .then( console.log )
          .then( console.log );
        document.getElementById( event.target.id ).style.pointerEvents = 'auto';
      }else {
        // setDisableButton( false );
        setDisableButton( !isEnabled || isFormSubmitted );
        document.getElementById( event.target.id ).style.pointerEvents = 'auto';

      }
    }, 1000 );


    // updateAgent( { variables:
    // { _eq: agent.auth0_id,
    //   company,
    //   custom_url: customUrl,
    //   description,
    //   email,
    //   facebook: social.facebook,
    //   first_name: name.split( ' ' )[0],
    //   last_name:  name.split( ' ' )[1],
    //   instagram: social.instagram,
    //   linkedin: social.linkedin,
    //   profile_url: profilePic, twitter: social.twitter } } )
    //   .then( console.log )
    //   .then( console.log );
  }

  // function validateForm(){
  //   const emailPattern = /^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$/;
  //   const namePattern = /^[a-zA-z]*$/;
  //   const instagram = /^((https?):\/\/)?(www.)?instagram\.com+(\/[a-zA-Z0-9#]+\/?)*$/;
  //   const facebook = /^((https?):\/\/)?(www.)?facebook\.com+(\/[a-zA-Z0-9#]+\/?)*$/;
  //   const twitter = /^((https?):\/\/)?(www.)?twitter\.com+(\/[a-zA-Z0-9#]+\/?)*$/;
  //   const linkedIn = /^((https?):\/\/)?(www.)?linkedin\.com+(\/[a-zA-Z0-9#]+\/?)*$/;
  //   return (  namePattern.test( name ) &&  emailPattern.test( email ) );
  // }

  const handleNameChange = ( event ) => {
    setName( event.target.value );
    setPageLoad( false );
    if ( event?.target?.value?.trim() == '' || !event?.target?.value?.match( /^[a-zA-Z ]+$/ ) ) {
      setNameError( 'Please enter valid name' );
      validateKey( 'name', false );
    } else {
      setNameError( '' );
      validateKey( 'name', true );
    }
  };

  function validateKey( key, value ){
    console.log( 'validating', key, value );
    const valid = { [key]: value };
    setValidator( validators => ( {
      ...validators,
      ...valid
    } ) );
    console.log( 'validators',validators );
  }

  const handleEmailChange = ( event ) => {
    setPageLoad( false );
    setEmail( event.target.value );
    if ( !event.target.value.match( /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/ ) ) {
      setEmailError( 'Please enter valid email address' );
      validateKey( 'email', false );
    } else {
      setEmailError( '' );
      validateKey( 'email', true );
    }
  };

  const handleCompanyChange = ( event ) => {
    setCompany( event.target.value );
    event.target.value == '' ? setCompanyError( 'Please enter valid company name' ) : setCompanyError( '' );
  };


  // const handleDescriptionChange = ( event ) => {
  //   setDescription( event.target.value );
  //   if ( event.target.value.length > 200 ) {
  //     setDescriptionError( 'Description should not more than 200 characters long' );
  //     validateKey( 'description', false );
  //   } else {
  //     setDescriptionError( '' );
  //     validateKey( 'description', true );
  //   }
  // };

  const handleDescriptionChange = ( event ) => {
    const maxChars = 200;
    const remainingChars = maxChars - event.target.value.length;
    let descriptionText = event.target.value;

    if ( remainingChars < 0 ) {
      descriptionText = descriptionText.slice( 0, maxChars );
    }

    setDescription( descriptionText );

    if ( remainingChars < 0 ) {
      setDescriptionError( <span style={{ color: 'red' }}>Description should not exceed {maxChars} characters.</span> );
      validateKey( 'description', false );
    } else {
      setDescriptionError( <span style={{ color: 'black' }}>{remainingChars} characters left</span> );
      validateKey( 'description', true );
    }
  };


  function getPhoto( image ){
    setPhoto( image );
  }

  return (
    <>
      <ButtonAppBar/>

      <Box sx={{ width: '100%' }} className="profilespacesec">
        <Grid container fixed className="containerSpace" spacing={2}>
          <Grid item xs={12} sm={12} md={7} lg={7} className="mobpadding">
            <Grid item xs={12}>
              <div className='bread'>
                <Breadcrumbs aria-label="breadcrumb">
                  <Link underline="hover" color="inherit" href="/">
            HomePage
                  </Link>

                  <Typography color="text.primary">
            My Profile
                  </Typography>
                </Breadcrumbs>
              </div>

              <Typography
                variant="h6"
                noWrap
                sx={{
                  mr: 2,
                  display: {  md: 'flex' },
                  textDecoration: 'none',
                  fontSize: '32px',
                  fontWeight: '700',
                  color:'#646464',
                  fontFamily: 'Poppins'
                }}
              >
                      My Profile
              </Typography>

              <Typography
                variant="h4"
                noWrap
                sx={{
                  mr: 2,
                  display: {  md: 'flex' },
                  textDecoration: 'none',
                  font: '300 16px Poppins',
                  color:'#646464',
                  margin:'0px 0px 24px'
                }}
              >
                    View and Edit your profile below
              </Typography>
            </Grid>

            <Grid item xs={12} spacing={4} className="mobpadding">
              <div className="row">
                <Grid item xs={12} sm={12} md={4} lg={4} display="flex" flexDirection="column" alignItems="center" >

                  <ProfilePhoto
                    updateProfilePicUrl={updateProfilePicUrl}
                    firePopUp={firePopUp}
                    user_id={agent?.auth0_id || ''}
                    getFile={getPhoto}
                    src={profilePic}/>


                </Grid>

                <Grid item xs={12} sm={12} md={8} lg={8}>
                  <TextField sx={{ width:'100%' }}
                    className="input-contact"
                    id="outlined-basic"
                    value={name}
                    onChange={handleNameChange}
                    label="Name"
                    variant="filled"
                    required

                  />

                  {nameError && <Typography className='errorTypo'>{nameError}</Typography>}


                  <TextField sx={{ width:'100%' }}
                    className="input-contact"
                    id="outlined-basic"
                    value={email}
                    disabled
                    onChange={handleEmailChange}
                    label= "Email"
                    variant="filled"
                    required

                  />


                </Grid>

                {emailError && <Typography className='errorTypo'>{emailError}</Typography>}


              </div>

              <div className="row">
                <Grid item lg={6} className="pl-15">
                  <TextField sx={{ width:'100%' }}
                    className="input-contact"
                    id="outlined-basic"
                    type="text"
                    name='company'
                    value={company}
                    onChange={handleCompanyChange}
                    label={
                      <>
                        Company
                      </>
                    }
                    variant="filled"
                  />

                  {companyError && <Typography className='errorTypo'>{companyError}</Typography>}
                </Grid>

                <UrlComponent5
                  name={'Website'}
                  pattern={/^((http(s):\/\/.))?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)$/}
                  validation={validation}
                  keyName={'website'}
                  updateSocial={setCustomUrl}
                  validateKey={validateKey}
                  url={customUrl}/>

                {/* <Grid item lg={6} className="pl-10">
                  <CssTextField sx={{ width:'100%' }}
                    className="input-contact"
                    id="outlined-basic"
                    value={customUrl}
                    onChange={( e ) => setCustomUrl( e.target.value )}
                    label={
                      <>
                        Your Website
                      </>
                    }
                    variant="filled"
                  />
                </Grid> */}

                <Grid item lg={12} xs={12} className="pl-15">
                  <TextField sx={{ width:'100%' }}
                    className="input-contact"
                    id="outlined-basic"
                    multiline
                    rows={3}
                    value={description || ''}
                    onChange={handleDescriptionChange}
                    label={
                      <>
                        Description
                      </>
                    }
                    variant="filled"
                  />

                  {descriptionError && <Typography>{descriptionError}</Typography>}

                </Grid>
              </div>

              <div className="sociallist">
                <div className="row">
                  <Grid item xs={12} sm={12} md={12} lg={6} className="pr-10 pl-15">
                    <div className="row">
                      <Grid item xs={2} sm={2} md={2} lg={3} display="flex" flexDirection="column" alignItems="center" >

                        <img
                          src="https://public-bubble.s3.us-west-2.amazonaws.com/images/Instagram.svg"
                          alt="Instagram"
                          loading="lazy"
                        />

                      </Grid>

                      <UrlComponent
                        name={'Instagram'}
                        pattern={/^((https?):\/\/)?(www.)?instagram\.com+(\/[a-zA-Z_\-0-9#\.]+\/?)*$/}
                        validation={validation}
                        keyName={'instagram'}
                        updateSocial={updateSocial}
                        validateKey={validateKey}
                        url={social.instagram}/>

                    </div>
                  </Grid>

                  <Grid item xs={12} sm={12} md={12} lg={6} className="pl-10 pr-15">
                    <div className="row">
                      <Grid item xs={2} sm={2} md={2} lg={3} display="flex" flexDirection="column" alignItems="center" >

                        <img
                          src="https://public-bubble.s3.us-west-2.amazonaws.com/images/Facebook.svg"
                          alt="Facebook"
                          loading="lazy"
                        />

                      </Grid>

                      <UrlComponent
                        name={'Facebook'}
                        pattern={/^((https?):\/\/)?(www.)?facebook\.com+(\/[a-zA-Z_\-0-9#\.]+\/?)*$/}
                        validation={validation}
                        keyName={'facebook'}
                        updateSocial={updateSocial}
                        validateKey={validateKey}
                        url={social.facebook}/>

                      {/* <Grid item xs={10} sm={10} md={10} lg={9} className="mobspaceright">
                        <CssTextField sx={{ width:'100%' }}
                          className="input-contact"
                          id="outlined-basic"
                          value={social.facebook}
                          onChange={( e ) => socialChange( e, 'facebook' )}
                          label={
                            <>
                              Facebook
                            </>
                          }
                          variant="filled"
                        />
                      </Grid> */}
                    </div>
                  </Grid>

                  <Grid item xs={12} sm={12} md={12} lg={6} className="pr-10 pl-15">
                    <div className="row">
                      <Grid item xs={2} sm={2} md={2} lg={3} display="flex" flexDirection="column" alignItems="center" >

                        <img
                          src="https://public-bubble.s3.us-west-2.amazonaws.com/images/Twitter.svg"
                          alt="Twitter"
                          loading="lazy"
                        />

                      </Grid>

                      <UrlComponent
                        name={'Twitter'}
                        pattern={/^((https?):\/\/)?(www.)?twitter\.com+(\/[a-zA-Z_\-0-9#\.]+\/?)*$/}
                        validation={validation}
                        keyName={'twitter'}
                        updateSocial={updateSocial}
                        validateKey={validateKey}
                        url={social.twitter}/>

                      {/* <Grid item xs={10} sm={10} md={10} lg={9} className="mobspaceright">
                        <CssTextField sx={{ width:'100%' }}
                          className="input-contact"
                          id="outlined-basic"
                          value={social.twitter}
                          onChange={( e ) => socialChange( e, 'twitter' )}
                          label={
                            <>
                              Twitter
                            </>
                          }
                          variant="filled"
                        />
                      </Grid> */}
                    </div>
                  </Grid>

                  <Grid item xs={12} sm={12} md={12} lg={6} className="pl-10 pr-15">
                    <div className="row">
                      <Grid item xs={2} sm={2} md={2} lg={3} display="flex" flexDirection="column" alignItems="center" >

                        <img
                          src="https://public-bubble.s3.us-west-2.amazonaws.com/images/Linkedin.svg"
                          alt="Linkedin"
                          loading="lazy"
                        />

                      </Grid>

                      <UrlComponent
                        name={'LinkedIn'}
                        pattern={/^((https?):\/\/)?(www.)?linkedin\.com+(\/[a-zA-Z_\-0-9#\.]+\/?)*$/}
                        validation={validation}
                        keyName={'linkedin'}
                        updateSocial={updateSocial}
                        validateKey={validateKey}
                        url={social.linkedin}/>

                      {/* <Grid item xs={10} sm={10} md={10} lg={9} className="mobspaceright">
                        <CssTextField sx={{ width:'100%' }}
                          className="input-contact"
                          id="outlined-basic"
                          value={social.linkedin}
                          onChange={( e ) => socialChange( e, 'linkedin' )}
                          label={
                            <>
                              Linkedin
                            </>
                          }
                          variant="filled"
                        />
                      </Grid> */}
                    </div>
                  </Grid>
                </div>
              </div>

              <div className="row">
                <Grid item xs={12}>
                  <button
                    disabled={ !name ||  !email || !company || nameError || emailError   }
                    onClick={sendForm}
                    id="saveUpdate"
                    className='orange-button round-button button-lg btnspace'
                    style={{
                      marginBottom: '20px',
                      backgroundColor:   name && email && company ? ' #ff7007' : '#ff7007',
                      color: name && email && company  ? 'white' : 'gray',
                      cursor:  name && email && company  ? 'pointer' : 'not-allowed'
                    }}
                  >
                    Save and update
                  </button>
                </Grid>
              </div>
            </Grid>
          </Grid>
        </Grid>
      </Box>

      <div className='myproffooter'>
        <Footer/>
      </div>

    </>
  );
}
