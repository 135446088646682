import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import React, { useEffect, useState } from 'react';

const CssTextField = styled( TextField )( {
  '& label.Mui-focused': {
    color: '#646464'
  },
  '& .MuiInput-underline:after': {
    borderBottomColor: '#DAE7EC'
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: '#DAE7EC'
    },
    '&:hover fieldset': {
      borderColor: '#DAE7EC'
    },
    '&.Mui-focused fieldset': {
      borderColor: '#DAE7EC'
    }
  }
} );

function UrlComponent ( props ) {

  const [url, setUrl] = useState( '' );
  const [linkIsInvalid, checkValidity] = useState( false );

  useEffect( () => {
    setUrl( props.url );
  }, [props.url] );

  function validate(){
    let isInvalid;
    if( url ){
      isInvalid = !props.pattern.test( url );
      checkValidity( isInvalid );
    } else {
      isInvalid = false;
      checkValidity( isInvalid );
    }
    props.validateKey( props.keyName, !isInvalid );
  }

  useEffect( () => {
    validate();
  }, [props.validation] );


  function changeUrl( event ) {
    const { value } = event.target;
    setUrl( value );
    props.updateSocial( props.keyName, value );
  }

  return (
    <>
      <Grid item xs={10} sm={10} md={10} lg={9} className="mobspaceright">
        <CssTextField sx={{ width:'100%' }}
          className="input-contact"
          id="outlined-basic"
          type="text"
          name={props.name}
          value={ url }
          error={ props.validation && linkIsInvalid }
          helperText={
            props.validation && linkIsInvalid
              ? `Please enter a valid ${props.name} link`
              : ''
          }
          onKeyUp={validate}
          onChange={changeUrl}
          label={
            <>
              {props.name}
            </>
          }
          variant="filled"
        />
      </Grid>
    </>
  );
}

export default UrlComponent;
